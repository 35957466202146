<template>
  <div>
    <!--    <base-template-->
    <!--        title="Incoming invoices"-->
    <!--        itemView="list"-->
    <!--        :columns="columns"-->
    <!--        :filters="filters"-->
    <!--        :items="incomingInvoices2"-->
    <!--        :context="incomingInvoicesContext"-->
    <!--        :isActionColumnEnabled="true"-->
    <!--        :openViewWindow="openViewWindow"-->
    <!--        :allow-add-by-upload="true"-->

    <!--        @fetch="fetchIncomingInvoices2"-->
    <!--        @rowSelected="rowSelected"-->
    <!--        @addItem="supplierInvoiceModalShow=true"-->
    <!--        @addItemByUpload="supplierInvoiceByUploadModalShow = true"-->
    <!--    >-->
    <!--      &lt;!&ndash;  =========================== &ndash;&gt;-->
    <!--      &lt;!&ndash;  ===      Grid view      === &ndash;&gt;-->
    <!--      &lt;!&ndash;  =========================== &ndash;&gt;-->
    <!--      <template v-slot:gridView="{item}">-->
    <!--        <incoming-invoice-card-->
    <!--            :incoming-invoice="item"-->
    <!--            :is-downloadable="false"-->
    <!--            :is-viewable="true"-->
    <!--            :is-payable="true"-->

    <!--            @click="showDetails(item)"-->
    <!--            @view="showDetails(item)"-->
    <!--        />-->
    <!--      </template>-->

    <!--      &lt;!&ndash;  =========================== &ndash;&gt;-->
    <!--      &lt;!&ndash;  ===      List view      === &ndash;&gt;-->
    <!--      &lt;!&ndash;  =========================== &ndash;&gt;-->
    <!--      &lt;!&ndash;      Actions&ndash;&gt;-->
    <!--      <template #listView_cell_actions="{item}">-->
    <!--        <div class="text-nowrap">-->

    <!--          &lt;!&ndash;          View&ndash;&gt;-->
    <!--          <button-view-->
    <!--              @click.native.stop="showDetails(item)"-->
    <!--              v-b-tooltip.hover.left="$t('View')"-->
    <!--              :withIcon="true"-->
    <!--              :withText="false"-->
    <!--              :withBorder="false"-->
    <!--              size="sm"-->
    <!--          />-->

    <!--          &lt;!&ndash;          Edit &ndash;&gt;-->
    <!--          <button-edit-->
    <!--              @click.native.stop="editSupplierInvoice(item)"-->
    <!--              v-b-tooltip.hover.left="$t('Edit')"-->
    <!--              :withIcon="true"-->
    <!--              :withText="false"-->
    <!--              :withBorder="false"-->
    <!--              size="sm"-->
    <!--          />-->

    <!--          &lt;!&ndash;          Download&ndash;&gt;-->
    <!--          <button-download-->
    <!--              v-b-tooltip.hover.left="$t('Download')"-->
    <!--              @click.native.stop="downloadIncomingInvoice(item)"-->
    <!--              :withIcon="true"-->
    <!--              :withText="false"-->
    <!--              :withBorder="false"-->
    <!--              size="sm"-->
    <!--          />-->

    <!--          &lt;!&ndash;          Duplicate&ndash;&gt;-->
    <!--          <button-duplicate-->
    <!--              v-b-tooltip.hover.left="capitalize($t('duplicate'))"-->
    <!--              @click.native.stop="duplicateIncomingInvoice(item)"-->
    <!--              :withIcon="true"-->
    <!--              :withText="false"-->
    <!--              :withBorder="false"-->
    <!--              size="sm"-->
    <!--          />-->

    <!--          &lt;!&ndash;          Delete&ndash;&gt;-->
    <!--          <button-remove-->
    <!--              v-b-tooltip.hover.left="capitalize($t('delete'))"-->
    <!--              @click.native.stop="deleteIncomingInvoiceAlert(item)"-->
    <!--              :withIcon="true"-->
    <!--              :withText="false"-->
    <!--              :withBorder="false"-->
    <!--              size="sm"-->
    <!--          />-->

    <!--        </div>-->
    <!--      </template>-->


    <!--      &lt;!&ndash;  =========================== &ndash;&gt;-->
    <!--      &lt;!&ndash;  ===     Select view     === &ndash;&gt;-->
    <!--      &lt;!&ndash;  =========================== &ndash;&gt;-->
    <!--      <template #leftViewWindow="{item}">-->
    <!--        <incoming-invoice-card-->
    <!--            :incoming-invoice="item"-->
    <!--            :is-downloadable="false"-->
    <!--            :is-viewable="true"-->
    <!--            :is-selected="item.id==selectedItems[0].id"-->

    <!--            @click="showDetails(item)"-->
    <!--            @view="showDetails(item)"-->
    <!--        />-->
    <!--      </template>-->
    <!--      <template #windowView-->
    <!--                v-if="selectedItems.length">-->
    <!--        <div class="d-flex justify-content-between mb-1">-->
    <!--          <h4>{{ selectedItems[0].billNumber }} - -->
    <!--            {{ selectedItems[0].supplierCompany ? selectedItems[0].supplierCompany._display : '' }}</h4>-->
    <!--          <div>-->

    <!--            &lt;!&ndash;          Edit &ndash;&gt;-->
    <!--            <button-edit-->
    <!--                @click.native.stop="editSupplierInvoice(selectedItems[0])"-->
    <!--                v-b-tooltip.hover.left="$t('Edit')"-->
    <!--                :withIcon="true"-->
    <!--                :withText="false"-->
    <!--                :withBorder="false"-->
    <!--                size="sm"-->
    <!--            />-->

    <!--            &lt;!&ndash;          Download&ndash;&gt;-->
    <!--            <button-download-->
    <!--                v-b-tooltip.hover.left="$t('Download')"-->
    <!--                @click.native.stop="downloadIncomingInvoice(selectedItems[0])"-->
    <!--                :withIcon="true"-->
    <!--                :withText="false"-->
    <!--                :withBorder="false"-->
    <!--                size="sm"-->
    <!--            />-->

    <!--            &lt;!&ndash;          Duplicate&ndash;&gt;-->
    <!--            <button-duplicate-->
    <!--                v-b-tooltip.hover.left="capitalize($t('duplicate'))"-->
    <!--                @click.native.stop="duplicateIncomingInvoice(selectedItems[0])"-->
    <!--                :withIcon="true"-->
    <!--                :withText="false"-->
    <!--                :withBorder="false"-->
    <!--                size="sm"-->
    <!--            />-->

    <!--            &lt;!&ndash;          Close&ndash;&gt;-->
    <!--            <button-close-->
    <!--                @click.native.stop="showDetails(null)"-->
    <!--                v-b-tooltip.hover.left="$t('Close')"-->
    <!--                :withIcon="true"-->
    <!--                :withText="false"-->
    <!--                :withBorder="false"-->
    <!--                size="sm"-->
    <!--            />-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <view-incoming-invoice-->
    <!--            class="mt-1 invoice-preview"-->
    <!--            :invoice="selectedItems[0]"-->
    <!--        />-->


    <!--      </template>-->
    <!--    </base-template>-->


    <!--    &lt;!&ndash; modal supplier invoice by upload&ndash;&gt;-->
    <!--    <modal-upload-->
    <!--        :single-file.sync="newSupplierInvoiceFile"-->
    <!--        :document-type="'incomingInvoice'"-->
    <!--        :title="capitalize($tc('invoice'))"-->
    <!--        :is-open.sync="supplierInvoiceByUploadModalShow"-->
    <!--    />-->

    <!--    &lt;!&ndash; modal incoming invoice&ndash;&gt;-->
    <!--    <modal-incoming-invoice-->
    <!--        :incoming-invoice.sync="currentSupplierInvoice"-->
    <!--        :title="('id' in currentSupplierInvoice)?$t('EditInvoice'):$t('NewIncomingInvoice')"-->
    <!--        :is-open.sync="supplierInvoiceModalShow"-->
    <!--        @submitValidatedIncomingInvoice="submitValidatedSuppliergInvoiceLocal"-->
    <!--    />-->

    <suppliers-invoices-view
        @reloadSuppliersInvoices="fetchIncomingInvoices2($event)"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useSupplierInvoices } from './useSupplierInvoices'
import { capitalize } from '../../../utils/filter'

// import BaseTemplate from '../../../components/base/Base'
// import IncomingInvoiceCard from '../../../components/card/IncomingInvoice2'
// import ViewIncomingInvoice from '../../../components/views/IncomingInvoice'
// import ButtonView from '../../../components/button/View'
// import ButtonDownload from '../../../components/button/Download'
// import ButtonClose from '../../../components/button/Close'
// import ButtonEdit from '../../../components/button/Edit.vue'
// import ButtonDuplicate from '../../../components/button/Duplicate.vue'
// import ButtonRemove from '../../../components/button/Remove.vue'
// import ModalIncomingInvoice from '../../../components/prompt/IncomingInvoice.vue'
// import ModalUpload from '../../../components/prompt/Upload.vue'

import i18n from '../../../libs/i18n'
import useAPI from '../../../utils/useAPI'
import store from '../../../store'

import SuppliersInvoicesView from '../../../components/views/SuppliersInvoicesView.vue'

export default {
  components: {
    // BaseTemplate,
    // IncomingInvoiceCard,
    // ViewIncomingInvoice,
    // ButtonView,
    // ButtonDownload,
    // ButtonClose,
    // ButtonEdit,
    // ButtonDuplicate,
    // ButtonRemove,
    // ModalIncomingInvoice,
    // ModalUpload
    SuppliersInvoicesView
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    // const columns = ref([
    //   {
    //     display: capitalize(i18n.t('supplier')),
    //     key: 'supplierCompany',
    //     sortable: true,
    //     cell: {
    //       useDisplay: true
    //     }
    //   },
    //   {
    //     display: capitalize(i18n.t('billingDate')),
    //     key: 'billingDate',
    //     sortable: true,
    //     cell: {
    //       isDate: true
    //     }
    //   },
    //   {
    //     display: capitalize(i18n.t('paymentDate')),
    //     key: 'paymentDate',
    //     sortable: true,
    //     cell: {
    //       isDate: true
    //     }
    //   },
    //   {
    //     display: capitalize(i18n.t('type')),
    //     key: 'type',
    //     sortable: true,
    //     cell: {
    //       useIcon: true,
    //       type: 'supplierInvoiceType',
    //     }
    //   },
    //   {
    //     display: capitalize(i18n.t('totalAmount')),
    //     key: 'totalAmount',
    //     sortable: true,
    //     cell: {
    //       isCurrency: true
    //     }
    //   },
    // ])
    // const filters = ref([
    //   {
    //     text: capitalize(i18n.tc('expenseAccount')),
    //     key: '_filter_isExpenseAccount',
    //     searchCriteria: [{
    //       column_name: 'paymentMethod',
    //       comparator: 'eq',
    //       search_text: '5',
    //     }],
    //   },
    //   {
    //     text: capitalize(i18n.tc('generalExpenses')),
    //     key: '_filter_isGeneralExpenseType',
    //     searchCriteria: [{
    //       column_name: 'type',
    //       comparator: 'eq',
    //       search_text: 'default',
    //     }],
    //   },
    //   {
    //     text: capitalize(i18n.tc('transport')),
    //     key: '_filter_isTollType',
    //     searchCriteria: [{
    //       column_name: 'type',
    //       comparator: 'eq',
    //       search_text: 'toll',
    //     }],
    //   },
    //   {
    //     text: capitalize(i18n.tc('accommodation')),
    //     key: '_filter_isAccommodationType',
    //     searchCriteria: [{
    //       column_name: 'type',
    //       comparator: 'eq',
    //       search_text: 'accommodation',
    //     }],
    //   },
    //   {
    //     text: capitalize(i18n.tc('catering')),
    //     key: '_filter_isCateringType',
    //     searchCriteria: [{
    //       column_name: 'type',
    //       comparator: 'eq',
    //       search_text: 'catering',
    //     }],
    //   },
    //   {
    //     text: capitalize(i18n.tc('production')),
    //     key: '_filter_isProductionType',
    //     searchCriteria: [{
    //       column_name: 'type',
    //       comparator: 'eq',
    //       search_text: 'production',
    //     }],
    //   },
    // ])
    // const openViewWindow = ref(false)
    // const selectedItems = ref([])
    // const supplierInvoiceModalShow = ref(false)
    // const supplierInvoiceByUploadModalShow = ref(false)
    // const currentSupplierInvoice = ref({})
    // const newSupplierInvoiceFile = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    // const {
    //   incomingInvoices2,
    //   incomingInvoicesContext
    // } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(newSupplierInvoiceFile, (val) => {
    //   if (val != null) {
    //     recognizeIncomingInvoice(val)
    //         .then(response => {
    //           supplierInvoiceByUploadModalShow.value = false
    //           newSupplierInvoiceFile.value = null
    //           currentSupplierInvoice.value = response.data
    //           supplierInvoiceModalShow.value = true
    //         })
    //   }
    // })
    //
    // watch(supplierInvoiceModalShow, (val) => {
    //   if (val == false) {
    //     resetSupplierInvoice()
    //   }
    // })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchAutocompleteBankAccounts,
      fetchAutocompleteContacts,
      fetchIncomingInvoices2,
      fetchPaymentMethods,
    } = useAPI()

    // const {
    //   downloadIncomingInvoice,
    //   recognizeIncomingInvoice,
    //   removeIncomingInvoice,
    //   submitValidatedIncomingInvoice,
    // } = useSupplierInvoices()
    //
    // const hasInitialFilter = () => {
    //   return filters.value.some(f => f.selected)
    // }
    //
    // const rowSelected = (rowSelected) => {
    //   selectedItems.value = rowSelected
    // }
    //
    // const showDetails = (incomingInvoice) => {
    //   if (incomingInvoice != null) {
    //     store.dispatch('incomingInvoice2/getIncomingInvoice', incomingInvoice.id)
    //         .then(response => {
    //           selectedItems.value = [incomingInvoice]
    //           selectedItems.value[0] = response
    //           openViewWindow.value = true
    //         })
    //
    //   } else {
    //     selectedItems.value = []
    //     openViewWindow.value = false
    //   }
    // }
    //
    // const resetSupplierInvoice = () => {
    //   currentSupplierInvoice.value = JSON.parse(JSON.stringify(store.getters['incomingInvoice2/getEmptyIncomingInvoice']))
    // }
    //
    // const submitValidatedSuppliergInvoiceLocal = () => {
    //   submitValidatedIncomingInvoice(currentSupplierInvoice.value)
    //       .then(() => {
    //         supplierInvoiceModalShow.value = false
    //         resetSupplierInvoice()
    //       })
    // }
    //
    // const editSupplierInvoice = (supplierInvoice) => {
    //   store.dispatch('incomingInvoice2/getIncomingInvoice', supplierInvoice.id)
    //       .then(response => {
    //         currentSupplierInvoice.value = response
    //         supplierInvoiceModalShow.value = true
    //       })
    // }
    //
    // const duplicateIncomingInvoice = (supplierInvoice) => {
    //   store.dispatch('incomingInvoice2/getIncomingInvoice', supplierInvoice.id)
    //       .then(response => {
    //         currentSupplierInvoice.value = response
    //
    //         delete currentSupplierInvoice.value.id
    //         delete currentSupplierInvoice.value.logs
    //         delete currentSupplierInvoice.value.orders
    //         delete currentSupplierInvoice.value.receipt
    //
    //         supplierInvoiceModalShow.value = true
    //       })
    // }
    //
    // const removeIncomingInvoiceLocal = (incomingInvoice) => {
    //   removeIncomingInvoice(incomingInvoice)
    //       .then(response => {
    //         // if (getRoute().name == 'IncomingInvoice view') {
    //         //   router.push({ name: 'IncomingInvoices' })
    //         // } else if (getRoute().name == 'Workflow view') {
    //         //   let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
    //         //   workflow.incomingInvoices = workflow.incomingInvoices.filter(ii => ii.id != incomingInvoice.id)
    //         // }
    //       })
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // resetSupplierInvoice()
    // if (!hasInitialFilter()) {
    fetchIncomingInvoices2().then(() => {
      fetchAutocompleteContacts()
      fetchPaymentMethods()
      fetchAutocompleteBankAccounts()
    })
    // }

    return {
      // Components
      capitalize,

      // Data
      // columns,
      // filters,
      // openViewWindow,
      // selectedItems,
      // supplierInvoiceModalShow,
      // supplierInvoiceByUploadModalShow,
      // currentSupplierInvoice,
      // newSupplierInvoiceFile,

      // Computed
      // incomingInvoices2,
      // incomingInvoicesContext,

      // Methods
      fetchIncomingInvoices2,
      // rowSelected,
      // showDetails,
      // downloadIncomingInvoice,
      // submitValidatedSuppliergInvoiceLocal,
      // editSupplierInvoice,
      // duplicateIncomingInvoice,
      // removeIncomingInvoiceLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    // deleteIncomingInvoiceAlert (incomingInvoice) {
    //   this.$bvModal
    //       .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theIncomingInvoice') }), {
    //         okVariant: 'danger',
    //         okTitle: this.capitalize(this.$t('delete')),
    //         cancelVariant: 'outline-secondary',
    //         cancelTitle: this.capitalize(this.$t('cancel')),
    //         centered: true,
    //       })
    //       .then(value => {
    //         if (value == true) {
    //           this.removeIncomingInvoiceLocal(incomingInvoice)
    //         }
    //       })
    // }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>